<template>
  <v-dialog persistent 
    v-model="activeModal"
    :max-width="variables.widthModal"
    
    class="modal-cadastro">
    <v-card>
      <v-toolbar flat height="40px" class="modal-cadastro-toolbar">
          <v-spacer></v-spacer>
          <v-icon @click="closeModal" class="modal-cadastro-close">close</v-icon>
      </v-toolbar>

      <v-col class="d-flex justify-center align-center" style="min-height: 300px; width: 100%;" v-if="loadingModal">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
      <v-form v-else>
        <v-card-title class="modal-cadastro-titulo">
          {{ name }}
        </v-card-title>
         <v-card-text class="modal-cadastro-form">
          <v-card-title class="text-center">
            Dados do Estabelecimento
          </v-card-title>
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field readonly v-model="dados.name" label="Nome do Estabelecimento:" /></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field readonly v-model="dados.cod" label="COD:" /></v-col>
            <!-- <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4">
              <v-select 
                :items="listaTipoEstabelecimento" 
                label="Tipo do estabelecimento" 
                v-model="dados.type" />
            </v-col> -->
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4"><v-text-field readonly v-model="dados.email" label="Email:" /></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4"><v-text-field readonly type="tel" v-model="dados.cnpj" label="CPF/CNPJ:" v-mask="['##.###.###/####-##', '###.###.###-##']"/></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="4"><v-text-field readonly type="tel" v-model="dados.phone" label="Telefone Comercial:" v-mask="['(##) ####-####', '(##) #####-####']"/></v-col>
          </v-row>
        </v-card-text>

        <v-card-text class="modal-cadastro-form">
          <v-card-title>
            Endereço do Estabelecimento
          </v-card-title>
          <v-row>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field readonly type="tel" v-model="dados.cep" label="CEP:" v-mask="['#####-###']"/></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field readonly v-model="dados.address" label="Endereço:" /></v-col>
              <!-- componente de select de Estado e cidade -->
              <searchUfCity
                @addUf="dados.state = arguments[0]"
                @addCity="dados.city = arguments[0]"
                :propUf="dados.state"
                :propCity="dados.city"
                :propReadonly="true" />

            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field readonly v-model="dados.neighborhood" label="Bairro:" /></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="6"><v-text-field readonly type="tel" v-model="dados.number" label="Número:" /></v-col>
            <v-col cols="12" class="modal-cadastro-form-col-imput" sm="12"><v-text-field readonly v-model="dados.complement" label="Complemento:" /></v-col>
          </v-row>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import variables from '@/assets/styles/helpers/_variables.scss'
import {size} from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import { mask } from 'vue-the-mask'

export default {
  name: 'InfoModal',
  directives: {mask},
  components: {
    searchUfCity: () => import('@/views/components/searchUfCity')
  },
  data: () => ({
    activeModal: false,
    loadingModal: false,
    item: {},
    type: '',
    dados: [],
    listaTipoEstabelecimento: [
      { text: '1 - Grande', value: 1 },
      { text: '2 - Médio', value: 2 },
      { text: '3 - Pequeno', value: 3 }
    ]
  }),
  computed: {
    ...mapGetters('estabelecimento', ['listaItens', 'totalItens', 'listaItensSelectCredenciador']),
    variables: () => variables,
    name () {
      if(size(this.item)) {
        return this.type === 'webUser' ? (this.item.webUserInfo !== null ? this.item.webUserInfo.entity.jsonData.name : "") : this.type === 'seller' ? (this.item.sellerInfo !== null ? this.item.sellerInfo.entity.jsonData.name : "") : this.item.name
      }
      return 'teste'
    }
  },
  mounted () {
    Events.$on('entidades::modalDados', item => {
      this.item = item.item
      this.openModal(item.item, item.type)
    })
  },
  methods: {
    ...mapActions('entities', ['obterItens']),
    closeModal () {
      this.activeModal = false
    },

    openModal (item, type) {
      this.activeModal = true
      this.loadingModal = true

      if (type === 'lancamento-financeiro') {
        if (item.entityTypeId !== 5) {
          this.obterItens({ id: item.id }).then(e => this.dados = e.resultMap[0]).finally(() => this.loadingModal = false)
          return
        }

        this.obterItens({ id: parseInt(item.parentId) }).then(e => this.dados = e.resultMap[0]).finally(() => this.loadingModal = false)
        return
      }
      
      if (item.sellerInfo.entity.entityTypeId === 5) {
        this.obterItens({ id: item.sellerInfo.entity.parentId }).then(e => this.dados = e.resultMap[0]).finally(() => this.loadingModal = false)
      } else {
        this.dados = item.sellerInfo.entity.jsonData
        this.loadingModal = false
      }

      this.dados.phone = this.formatNumber(this.dados.phone)
    },

    formatNumber (phone) {
      const numberArray = phone.split(' ')
  
      if (numberArray.length === 3) {
        if (numberArray[2].length === 9) {
          console.log(numberArray[2].replace(/(\d{5})(\d{4})/, (regex, arg1, arg2) => `(${numberArray[1]}) ${arg1} - ${arg2}`))
          return numberArray[2].replace(/(\d{5})(\d{4})/, (regex, arg1, arg2) => `(${numberArray[1]}) ${arg1} - ${arg2}`)
        } else {
          return numberArray[2].replace(/(\d{4})(\d{4})/, (regex, arg1, arg2) => `(${numberArray[1]}) ${arg1} - ${arg2}`)
        }
      }

      return ''
    }
  }
}
</script>

<style lang="scss">
  @import '~@/assets/styles/components/formularios';
  @import '~@/assets/styles/components/modal-cadastro';
</style>